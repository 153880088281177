import {AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel} from '@amzn/f3-excelsior-forecast-configuration-lambda';
import {DependencyList, useCallback} from 'react';
import {useExecute} from './use-async';
import {wrapOnErrorListener} from '../common';

export function useGetTenantConfiguration(
    clientConfiguration: AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel.Types.ClientConfiguration,
    onErrorListener: (e: any) => void,
    deps: DependencyList,
    client = new AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel(clientConfiguration)
) {
    return useExecute(
        useCallback(
            (request: AbeautifulandamazingF3ExcelsiorConfigurationLambdaModel.Types.GetConfigurationRequest) =>
                wrapOnErrorListener(client.getConfiguration(request).promise(), onErrorListener),
            deps
        )
    );
}
