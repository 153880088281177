import React, {useEffect, useState} from 'react';
import {Box, Button, Header, Icon, SpaceBetween, StatusIndicator, Table, TextFilter} from '@amzn/awsui-components-react';
import EventPopover from './event-popover';
import {Notification} from '../navigation/page-layout';
import {BusinessContext, BusinessId, Country, COUNTRY_TO_REGION_MAP, Flow, RegionId, useAuth} from '../hooks/use-auth';
import {DashboardEvent} from '@amzn/f3-excelsior-orchestrator-api/clients/orchestrator';
import {DashboardEventFetchStatus, useListDashboardEvents, useStartAdhocEvent} from '../hooks/use-orchestrator-api';
import {AsyncStatus} from '../hooks/use-async';
import {Orchestrator} from '@amzn/f3-excelsior-orchestrator-api';
import {translateErrorToReactNode} from '../common';
import {ButtonWithConfirmation} from '../common/button-with-confirmation';
import ViewStatisticsButton from './view-statistics-button';
import {useGetTenantConfiguration} from '../hooks/use-configuration-api';

const DAYS_OF_WEEK = 7;
const NUM_TRAILING_WEEK = 4;
const MILLISECOND_OF_A_DAY = 24 * 60 * 60 * 1000;

interface DashboardContentRow {
    business_context: BusinessContext;
    loadingStatus: AsyncStatus;
    schedulerStatus?: Orchestrator.Types.ScheduleStatus;
    eventsByDay: Map<number, DashboardEvent[]>;
}

export enum EventType {
    Generate,
}

export enum ForecastStartDate {
    at_start_of_next_week_sunday_zero_hour = 'at_start_of_next_week_sunday_zero_hour',
    at_start_of_week_sunday_zero_hour = 'at_start_of_week_sunday_zero_hour',
}

export default function ViewOperationalDashboard(props: {pushNotification: (notification: Notification) => void}) {
    const auth = useAuth();

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const [selectedItems, setSelectedItems] = useState([] as DashboardContentRow[]);
    const [weekOffset, setWeekOffset] = useState(0);

    const currentDateTime = new Date();
    const currentDayOfWeek = new Date(currentDateTime).getDay();
    // wrap value with useState to get stable identity
    const [maxStartDateTime] = useState(
        new Date(new Date(currentDateTime).setDate(currentDateTime.getDate() - DAYS_OF_WEEK * (NUM_TRAILING_WEEK + 1)))
    );
    // wrap value with useState to get stable identity
    const [maxEndDateTime] = useState(
        new Date(new Date(currentDateTime).setDate(currentDateTime.getDate() + DAYS_OF_WEEK * (NUM_TRAILING_WEEK + 1)))
    );
    const businessContexts = auth.authInformation?.businessContexts || [];
    const endpoints = auth.authInformation!.getEndpoints();

    const [filteringText, setFilteringText] = React.useState('');
    const {dashboardEventFetchStatus: dashboardEventFetchStatus, refreshSingle: refreshSingle} = useListDashboardEvents(
        endpoints,
        businessContexts,
        maxStartDateTime,
        maxEndDateTime,
        true
    );

    const isSelected = selectedItems.length === 1;
    const buttonVariant = isSelected ? 'primary' : 'normal';
    function isBusinessOnPublisherV1(business: string) {
        return business === 'wfminstore';
    }
    const selectedBusiness = isSelected ? selectedItems[0].business_context.businessId : '';
    const selectedCountry = isSelected ? selectedItems[0].business_context.country : '';
    const selectedRegion = isSelected ? COUNTRY_TO_REGION_MAP[selectedCountry] ?? RegionId.NA : RegionId.NA;
    const selectedFlow = isSelected ? selectedItems[0].business_context.flow : '';

    const orchestratorConfiguration = endpoints[selectedRegion].OrchestratorView;
    const configurationServiceApiConfiguration = endpoints[selectedRegion].ConfigurationView;

    const tenantKey = `${selectedBusiness}-${selectedCountry}-${selectedFlow}`;
    const isPublishDisabled = !isSelected || isBusinessOnPublisherV1(selectedBusiness);
    const isIngestionDisabled = !isSelected;

    const [modelGroupNameForGeneration, setModelGroupNameForGeneration] = useState('');
    const [forecastStartDateForGeneration, setForecastStartDateForGeneration] = useState('');

    const {execute: getConfiguration} = useGetTenantConfiguration(
        configurationServiceApiConfiguration,
        createErrorListener('Get tenant configuration failed'),
        [auth, selectedItems]
    );

    const {execute: startAdhocEvent} = useStartAdhocEvent(
        orchestratorConfiguration,
        createErrorListener('Ad-hoc execution failed'),
        [auth, selectedItems]
    );

    const generateDashboardContentRowKey = (businessId: string, country: string, flow: string) => {
        return `${businessId}_${country}_${flow}`;
    };

    const generateDashboardContentRow = (dashboardEventFetchStatus: DashboardEventFetchStatus): DashboardContentRow => {
        const businessContext = dashboardEventFetchStatus.businessContext;
        const status: AsyncStatus = dashboardEventFetchStatus.status;
        const eventsByDay = new Map<number, DashboardEvent[]>();

        for (let num = -(NUM_TRAILING_WEEK + 1) * DAYS_OF_WEEK; num <= (NUM_TRAILING_WEEK + 1) * DAYS_OF_WEEK; num++) {
            eventsByDay.set(num, []);
        }

        for (const event of dashboardEventFetchStatus.events) {
            const dayDiff: number = Math.round(
                (new Date(event.scheduledTime).setHours(0) - new Date(currentDateTime).setHours(0)) / MILLISECOND_OF_A_DAY
            );
            eventsByDay.get(dayDiff)?.push(event);
        }

        return {
            loadingStatus: status,
            schedulerStatus: dashboardEventFetchStatus.schedulerStatus,
            business_context: businessContext,
            eventsByDay: eventsByDay,
        };
    };

    const generateDashboardContent = (dashboardEventFetchStatusList: DashboardEventFetchStatus[]) => {
        const dashBoardContentRowsByWorkflow: Map<string, DashboardContentRow> = new Map<string, DashboardContentRow>();
        for (const dashboardEventFetchStatus of dashboardEventFetchStatusList) {
            dashBoardContentRowsByWorkflow.set(
                generateDashboardContentRowKey(
                    dashboardEventFetchStatus.businessContext.businessId,
                    dashboardEventFetchStatus.businessContext.country,
                    dashboardEventFetchStatus.businessContext.flow
                ),
                generateDashboardContentRow(dashboardEventFetchStatus)
            );
        }
        return dashBoardContentRowsByWorkflow;
    };

    const schedulerStatusComponent = ({schedulerStatus, loadingStatus}: DashboardContentRow) => {
        const indicatorType = schedulerStatus === 'running' ? 'success' : schedulerStatus === 'stopped' ? 'stopped' : 'loading';

        return (
            <StatusIndicator data-testid="scheduler-status" type={indicatorType}>
                {schedulerStatus ?? 'Loading ' + loadingStatus}
            </StatusIndicator>
        );
    };

    const generatePopoversByDayOfWeek = (item: DashboardContentRow, dayOfWeek: number) => {
        const eventsByDay = item.eventsByDay.get(-currentDayOfWeek + weekOffset * DAYS_OF_WEEK + dayOfWeek);
        if (eventsByDay && eventsByDay.length > 0) {
            return eventsByDay.map((event) => {
                return <EventPopover key={event.eventId} event={event} />;
            });
        } else {
            return <div>-</div>;
        }
    };

    const getDateOfDayOfWeek = (dayOfWeek: number) => {
        return new Date(
            new Date(currentDateTime).setDate(currentDateTime.getDate() - currentDayOfWeek + weekOffset * DAYS_OF_WEEK + dayOfWeek)
        ).toLocaleDateString();
    };

    // TODO: (https://i.amazon.com/issues/GSC-Forecasting-EPIC-1545)
    // Refactor this function/configuration out. Hardcoding the `startTimeCalculation` configuration here for which tenants should use `at_start_of_next_week_sunday_zero_hour`is not good.
    // One better way could be to store this configuration in Orchestrator (updated whenever the Orchestrator updates the generate api schedule) and have the Adhoc API pull this configuration from that config.
    const getStartDateForForecastGenerator = (businessId: string, country: string, flow: string) => {
        // temporary method: checking if it is EU UFF OB tenant
        const currentLocalDateTime = new Date(currentDateTime.getTime() - currentDateTime.getTimezoneOffset() * 60000);
        if (
            businessId.toLowerCase() === BusinessId.UFF &&
            flow.toLowerCase() === Flow.OUTBOUND.toLowerCase() &&
            [
                Country.GB.valueOf(),
                Country.DE.valueOf(),
                Country.IT.valueOf(),
                Country.ES.valueOf(),
                Country.FR.valueOf(),
                Country.IN.valueOf(),
            ].includes(country.toUpperCase()) &&
            currentLocalDateTime.getDay() >= 5
        ) {
            // At Start Of Next Week Sunday Zero Hour
            // EU UFF OUTBOUND needs forecasts generated before the week starts https://i.amazon.com/issues/GSC-Forecasting-EPIC-879
            return 'at_start_of_next_week_sunday_zero_hour';
        }
        // At Start Of Week Sunday Zero Hour
        return 'at_start_of_week_sunday_zero_hour';
    };

    const getModelGroupNameForForecastGenerator = async () => {
        if (selectedBusiness !== '' && selectedCountry !== '' && selectedFlow !== '') {
            const response = await getConfiguration({
                business: selectedBusiness,
                country: selectedCountry,
                flow: selectedFlow,
                groupId: 'forecast',
            });
            const tenantConfiguration = response.configuration || '';
            setModelGroupNameForGeneration(JSON.parse(tenantConfiguration).modelGroupName);
        }
    };

    const dashboardContent = generateDashboardContent(dashboardEventFetchStatus);
    const selectedEvents = dashboardContent.has(generateDashboardContentRowKey(selectedBusiness, selectedCountry, selectedFlow))
        ? dashboardContent.get(generateDashboardContentRowKey(selectedBusiness, selectedCountry, selectedFlow))!.eventsByDay
        : new Map<number, Orchestrator.DashboardEvent[]>();

    const columnDefinitions = [
        {
            id: 'business',
            header: 'Business',
            cell: (item: DashboardContentRow) => item.business_context.businessId,
            minWidth: '60px',
        },
        {
            id: 'country',
            header: 'Country',
            cell: (item: DashboardContentRow) => item.business_context.country,
            minWidth: '60px',
        },
        {
            id: 'flow',
            header: 'Flow',
            cell: (item: DashboardContentRow) => item.business_context.flow,
            minWidth: '50px',
        },
        {
            id: 'scheduler-status',
            header: 'Scheduler Status',
            cell: (item: DashboardContentRow) => schedulerStatusComponent(item),
            minWidth: '70px',
        },
        {
            id: 'sunday',
            header: `Sun  ${getDateOfDayOfWeek(0)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 0),
            minWidth: '100px',
        },
        {
            id: 'monday',
            header: `Mon  ${getDateOfDayOfWeek(1)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 1),
            minWidth: '100px',
        },
        {
            id: 'tuesday',
            header: `Tue  ${getDateOfDayOfWeek(2)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 2),
            minWidth: '100px',
        },
        {
            id: 'wednesday',
            header: `Wed  ${getDateOfDayOfWeek(3)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 3),
            minWidth: '100px',
        },
        {
            id: 'thursday',
            header: `Thu  ${getDateOfDayOfWeek(4)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 4),
            minWidth: '100px',
        },
        {
            id: 'friday',
            header: `Fri  ${getDateOfDayOfWeek(5)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 5),
            minWidth: '100px',
        },
        {
            id: 'saturday',
            header: `Sat  ${getDateOfDayOfWeek(6)}`,
            cell: (item: DashboardContentRow) => generatePopoversByDayOfWeek(item, 6),
            minWidth: '100px',
        },
    ];

    const publishForecastModalExecute = () => {
        props.pushNotification({
            content: 'Forecast Publishing is currently in process. See Forecast Store AWS account for status.',
            type: 'info',
        });

        startAdhocEvent({
            businessId: selectedBusiness,
            country: selectedCountry,
            flow: selectedFlow,
            promoteAndPublishEvent: {
                forecastType: 'SOP',
                method: 'cradle',
            },
        });
    };

    const generateForecastModalExecute = () => {
        props.pushNotification({
            content: 'Forecast Generation is currently in process. See Forecast Generator AWS account for status.',
            type: 'info',
        });

        startAdhocEvent({
            businessId: selectedBusiness,
            country: selectedCountry,
            flow: selectedFlow,
            generateEvent: {
                forecastType: 'SOP',
                modelGroupName: modelGroupNameForGeneration,
                startTimeCalculation: forecastStartDateForGeneration,
            },
        });
    };

    const constraintsIngestionModalExecute = () => {
        props.pushNotification({
            content: 'Constraints ingestion is currently in process. See Forecast Store AWS account for status.',
            type: 'info',
        });

        startAdhocEvent({
            businessId: selectedBusiness,
            country: selectedCountry,
            flow: selectedFlow,
            ingestEvent: {
                data: 'constraints',
            },
        });
    };

    const actualsIngestionModalExecute = () => {
        props.pushNotification({
            content: 'Actuals ingestion is currently in process. See Forecast Store AWS account for status.',
            type: 'info',
        });

        startAdhocEvent({
            businessId: selectedBusiness,
            country: selectedCountry,
            flow: selectedFlow,
            ingestEvent: {
                data: 'actuals',
            },
        });
    };

    return (
        <>
            <div className="main-header">
                <Header variant="h2">Events By Week</Header>
                <Box color="text-status-info" fontWeight="heavy">
                    Succeed Event
                </Box>
                <Box color="text-status-error" fontWeight="heavy">
                    Error Event
                </Box>
                <Box color="text-status-success" fontWeight="heavy">
                    In Progress Event
                </Box>
                <Box color="text-status-inactive" fontWeight="heavy">
                    Not Started Event
                </Box>
                <Box fontWeight="heavy">
                    <Icon name="status-warning" size="inherit" variant="warning" />: SLA Breached Events
                </Box>
            </div>
            <div className="main-content">
                <Table
                    data-testid="operational-dashboard"
                    onSelectionChange={({detail}) => setSelectedItems(detail.selectedItems)}
                    selectedItems={selectedItems}
                    selectionType="single"
                    trackBy={(row: DashboardContentRow) =>
                        row.business_context.businessId + '_' + row.business_context.country + '_' + row.business_context.flow
                    }
                    header={
                        <Header
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        data-testid="refresh"
                                        iconName="refresh"
                                        variant="normal"
                                        disabled={selectedItems.length > 0 ? false : true}
                                        onClick={() => {
                                            selectedItems.map((dashboardEvent) => refreshSingle(dashboardEvent.business_context));
                                        }}
                                    />
                                    <Button
                                        data-testid="last-week"
                                        disabled={weekOffset <= -NUM_TRAILING_WEEK ? true : false}
                                        onClick={() => {
                                            setWeekOffset(weekOffset - 1);
                                        }}
                                    >
                                        Last Week
                                    </Button>
                                    <Button
                                        data-testid="current-week"
                                        onClick={() => {
                                            setWeekOffset(0);
                                        }}
                                    >
                                        Current
                                    </Button>
                                    <Button
                                        data-testid="next-week"
                                        disabled={weekOffset >= NUM_TRAILING_WEEK ? true : false}
                                        onClick={() => {
                                            setWeekOffset(weekOffset + 1);
                                        }}
                                    >
                                        Next Week
                                    </Button>
                                    <ButtonWithConfirmation
                                        label="Generate Forecast"
                                        variant={buttonVariant}
                                        disabled={!isSelected}
                                        promptHeader={'Generate Forecast'}
                                        prompt={`Are you sure you want to generate forecast for ${tenantKey}?`}
                                        confirmPhrase={`${tenantKey}`}
                                        onConfirm={generateForecastModalExecute}
                                        onclickAdditional={getModelGroupNameForForecastGenerator}
                                        eventType={EventType.Generate}
                                        defaultForecastStartDateForGeneration={getStartDateForForecastGenerator(
                                            selectedBusiness,
                                            selectedCountry,
                                            selectedFlow
                                        )}
                                        modelGroupName={modelGroupNameForGeneration}
                                        updateModelGroupName={(modelGroupName) => setModelGroupNameForGeneration(modelGroupName)}
                                        updateForecastStartDate={(forecastStartDate) =>
                                            setForecastStartDateForGeneration(forecastStartDate)
                                        }
                                    />
                                    <ButtonWithConfirmation
                                        label="Promote & Publish Forecast"
                                        disabled={isPublishDisabled}
                                        variant={buttonVariant}
                                        promptHeader={'Publish Forecast'}
                                        prompt={`Are you sure you want to publish forecast for ${tenantKey}?`}
                                        confirmPhrase={`${tenantKey}`}
                                        onConfirm={publishForecastModalExecute}
                                    />
                                    <ButtonWithConfirmation
                                        label="Ingest Constraints"
                                        disabled={isIngestionDisabled}
                                        variant={buttonVariant}
                                        promptHeader={'Ingest Constraints'}
                                        prompt={`Are you sure you want to ingest constraints for ${tenantKey}?`}
                                        confirmPhrase={`${tenantKey}`}
                                        additionalInfo={`Ingestion can cut a ticket if not setup for ${tenantKey}.`}
                                        onConfirm={constraintsIngestionModalExecute}
                                    />
                                    <ButtonWithConfirmation
                                        label="Ingest Actuals"
                                        disabled={!isSelected}
                                        variant={buttonVariant}
                                        promptHeader={'Ingest Actuals'}
                                        prompt={`Are you sure you want to ingest actuals for ${tenantKey}?`}
                                        confirmPhrase={`${tenantKey}`}
                                        onConfirm={actualsIngestionModalExecute}
                                    />
                                    <ViewStatisticsButton
                                        label="View Statistics"
                                        disabled={!isSelected}
                                        variant={buttonVariant}
                                        orchestratorEvents={Array.from(selectedEvents.values()).flat()}
                                    />
                                </SpaceBetween>
                            }
                        >
                            {weekOffset == 0 ? 'Current ' : ''}Week : {getDateOfDayOfWeek(0)} to {getDateOfDayOfWeek(6)}
                        </Header>
                    }
                    loadingText="Loading resources"
                    columnDefinitions={columnDefinitions}
                    items={Array.from(dashboardContent.entries())
                        .filter((entry) => {
                            return entry[0].includes(filteringText) ? true : false;
                        })
                        .sort((a, b) => {
                            return a[0].localeCompare(b[0]);
                        })
                        .map((entry) => entry[1])}
                    filter={
                        <TextFilter
                            filteringPlaceholder="Find workflows"
                            filteringAriaLabel="Filter workflows"
                            filteringText={filteringText}
                            onChange={({detail}) => {
                                setFilteringText(detail.filteringText);
                            }}
                        />
                    }
                ></Table>
            </div>
        </>
    );
}
