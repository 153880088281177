import {Box, Button, Modal, SpaceBetween, Table} from '@amzn/awsui-components-react';
import React, {useState} from 'react';
import PreviewTemplateTable from './preview-template-table';
import {useGetOriginalUploadArtifact} from '../hooks/use-forecast-store-api';
import {Notification} from '../navigation/page-layout';
import {ServiceResource, useAuth} from '../hooks/use-auth';
import {translateErrorToReactNode} from '../common';

interface PreviewTemplateButtonProps {
    pushNotification: (notification: Notification) => void;
    forecastId: string;
    uploadId: string;
}

export default function PreviewTemplateButton(props: PreviewTemplateButtonProps) {
    const auth = useAuth();
    const [visible, setVisible] = useState(false);

    const forecastStoreViewClientConfiguration = auth.authInformation!.getCurrentServiceEndpoint(ServiceResource.ForecastStoreView);

    function createErrorListener<T>(header: string) {
        return (e: any) => {
            props.pushNotification({
                type: 'error',
                content: translateErrorToReactNode(e),
                header,
            });
        };
    }

    const {value: template, execute: executeGetTemplate} = useGetOriginalUploadArtifact(
        forecastStoreViewClientConfiguration,
        createErrorListener('GetForecastAssetDownloadUrlStatus failed'),
        [auth]
    );

    return (
        <>
            <Button
                data-testid="preview-template-button"
                iconName="search"
                variant="icon"
                onClick={async () => {
                    setVisible(true);
                    if (!template) {
                        await executeGetTemplate({
                            forecastId: props.forecastId,
                            uploadId: props.uploadId,
                        });
                    }
                }}
            />
            <Modal
                data-testid="preview-template-modal"
                visible={visible}
                header="Template Preview"
                size="max"
                onDismiss={() => setVisible(false)}
                footer={
                    <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button
                                data-testid="preview-template-modal-ok-button"
                                variant="primary"
                                onClick={() => setVisible(false)}
                            >
                                Ok
                            </Button>
                        </SpaceBetween>
                    </Box>
                }
            >
                <PreviewTemplateTable template={template} visible={visible} />
            </Modal>
        </>
    );
}
